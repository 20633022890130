.container {
    @apply w-full border-b border-black/20 h-12 flex flex-row items-center justify-between ;
}

.theme_tools {
    @apply flex flex-row items-center ;
}

.profile_tools {
    @apply flex flex-row-reverse items-center gap-x-3 ;
}