.container {
    @apply w-full flex flex-col items-center gap-y-3 ;
}

.dash_header {
    @apply  w-full flex items-center h-12 justify-between px-4 ;
}

.statistics {
    @apply w-full h-64 max-[1090px]:h-auto flex flex-row max-[1090px]:flex-wrap max-[1090px]:gap-y-3 ;
}

.activity {
    @apply flex flex-col items-center w-full h-auto py-4 overflow-hidden overflow-y-auto ;
}

.activity::-webkit-scrollbar
{
    width: 0px;
    background-color: transparent;
}

.recent_sales {
    @apply w-full flex flex-col items-start overflow-hidden overflow-y-auto py-1  ;
}

.recent_sales::-webkit-scrollbar
{
    width: 0px;
    background-color: transparent;
}
