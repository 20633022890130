.container {
    @apply w-full p-0
}

.header_operator {
    @apply w-full flex flex-col items-start ;

}

.tools {
    @apply w-full h-auto flex flex-row items-center pt-4 mb-2 px-2 justify-between  ;
}

.title {
    @apply text-2xl font-semibold font-sans text-black/60 m-2 p-1 bg-blue-800 rounded-lg text-white flex flex-row items-center gap-x-2;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;

}

.action_buttons {
    @apply px-2 py-1 hover:-translate-y-1 duration-500 active:translate-y-0 rounded-xl duration-500 cursor-pointer;
}

.ant-table-content thead tr .ant-table-cell {
    @apply bg-blue-400/30  ;
}
.ant-table-content thead tr .ant-table-cell:last-child{
    @apply bg-blue-400/30 text-center ;
}

.ant-table-wrapper tbody tr:nth-child(even) {
    @apply bg-blue-400/30 ;
}