.custom_select .ant-select .ant-select-selector {
    @apply bg-black border-white/40 ;
}

.custom_select_dark .ant-select .ant-select-selector {
    @apply bg-black border-white/40 text-white ;
}

.custom_dropdown .ant-dropdown .ant-dropdown-menu  {
    @apply bg-gray-800 ;
}
