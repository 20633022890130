.site_layout_background {
    @apply relative duration-500 overflow-y-auto mb-6 overflow-hidden rounded-l-3xl ;
}

.site_layout_background::-webkit-scrollbar {
    width: 0px;
}

.logo_place {
    @apply w-full flex flex-row pt-4 pl-4 items-center justify-between relative ;
}

.menu {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    @apply w-full flex flex-col h-auto items-start mt-3 pl-5  relative  ;
}

.menu_item {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    @apply w-full h-16  flex flex-row items-start justify-between  pl-4 rounded-3xl duration-500 relative;
}

.menu_item_active {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    @apply w-full h-16 flex flex-row items-start justify-between  pl-4 rounded-3xl  duration-500 relative;
}

.links {
    @apply ml-9  whitespace-nowrap text-lg shadow-black line-clamp-1 no-underline;
}

.sub_links {
    @apply ml-9 whitespace-nowrap text-xl shadow-black line-clamp-1 no-underline;
}